export default function transformFilterArray(filters, labelKey) {
  let newFilters = [];

  if (filters && filters.length > 0) {
    newFilters = filters.map(({ id, pin_color: pinColor, ...otherProps }) => ({
      ...otherProps,
      id,
      value: id,
      pinColor,
      labelKey: `${labelKey}_${id}`,
    }));
  }

  return newFilters;
}
