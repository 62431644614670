import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Logo from "../Logo";
import Menu from "../Menu";
import SelectLanguage from "../SelectLanguage";
import BackToSite from "../BackToSite";
import ProgressBar from "../ProgressBar";
import { useFilters, useGlobal } from "frontend/hooks";

import styles from "./SiteHeader.module.scss";

const SiteHeader = ({ containerClass }) => {
  const location = useLocation();
  const { resetFilters } = useFilters();
  const { title } = useGlobal();

  const handleClick = (e) => {
    const { pathname } = location;
    if (pathname === "/") {
      resetFilters();
    }
  };

  return (
    <div className={classNames(styles.header, containerClass)}>
      <div className={styles.header__inner}>
        <div className={styles.header__logo}>
          <RouterLink className="a-focus-default" to="/" onClick={handleClick}>
            <Logo />
            <span className="sr-only">{title}</span>
          </RouterLink>
        </div>
        <div className={styles.header__button}>
          <BackToSite />
        </div>
        <div className={styles.header__language}>
          <SelectLanguage />
        </div>
        <div className={styles.header__menu}>
          <Menu />
        </div>
      </div>
      <ProgressBar />
    </div>
  );
};

SiteHeader.propTypes = {
  containerClass: PropTypes.string,
};

export default SiteHeader;
