import React, {
  useEffect,
  useReducer,
  createContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { useQueryFilters } from "frontend/hooks";

const DEFAULT_FILTERS = {
  distance: 15,
  distance_units: "imperial",
};

function filtersReducer(state, action) {
  // Remove reset from returned state
  const { reset, ...currentState } = state;
  // New filters
  const newFilters = {
    ...currentState,
    ...action.payload,
  };

  switch (action.type) {
    case "FILTERS_UPDATE":
      // Check if filters changed before updating them
      if (JSON.stringify(newFilters) !== JSON.stringify(currentState)) {
        return {
          ...currentState,
          ...action.payload,
        };
      }

      return { ...currentState };
    case "FILTERS_RESET":
      return {
        ...DEFAULT_FILTERS,
        ...action.payload,
        reset: true,
      };
    default:
      return { ...currentState };
  }
}

export const FiltersContext = createContext();

export const FiltersProvider = ({ children, initFilters }) => {
  const [params, dispatch] = useReducer(
    filtersReducer,
    initFilters ?? DEFAULT_FILTERS
  );
  const { query, clearQuery } = useQueryFilters();

  const setFilters = useCallback(
    (filters) => {
      // Update filters
      dispatch({
        type: "FILTERS_UPDATE",
        payload: {
          distance_units: "imperial",
          ...filters,
        },
      });
    },
    [dispatch]
  );

  const resetFilters = useCallback(
    (filters) => {
      // Reset and update filters
      clearQuery();
      dispatch({
        type: "FILTERS_RESET",
        payload: {
          ...filters,
        },
      });
    },
    [dispatch, clearQuery]
  );

  // Update city_or_zip if query changes
  useEffect(() => {
    if (query.q !== params.city_or_zip) {
      setFilters({
        city_or_zip: query.q,
      });
    }
  }, [query, params, setFilters]);

  // Update active_campaign if query changes
  useEffect(() => {
    // query.campaign = 1 | undefined, active_campaign = true | false
    if (!!query.campaign !== !!params.active_campaign) {
      setFilters({
        active_campaign: query.campaign ? true : null,
        location_categories: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.campaign, params.active_campaign]);

  return (
    <FiltersContext.Provider value={{ params, setFilters, resetFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.node,
  initFilters: PropTypes.object,
};
