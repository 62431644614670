import React from "react";

const IconPinCarton = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 31.3 40"
    width="31.3"
    height="40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.2,1H4.1C2.2,1,0.6,2.6,0.6,4.5v22.6c0,1.9,1.6,3.4,3.4,3.4h6.4l5.3,8.6l5.3-8.6h6.4c1.9,0,3.4-1.6,3.4-3.4V4.5 C30.7,2.6,29.1,1,27.2,1z"
    />
    <g id="carton">
      <path
        fill="#FFFFFF"
        d="M18.7,7.6c-1.7,0-3.5,0-5.3,0V7.1h7.2V5.5c0-0.4-0.3-0.8-0.8-0.8h-7.9c-0.4,0-0.8,0.3-0.8,0.8v1.7 l-0.4,0.7l0,0l-0.1,0.1c-0.4,0.6-0.7,1.2-1.1,1.8l-0.3,0.5l-0.1,0.1l0,0.1l-0.4,0.8v13.9c0,0.8,0.7,1.5,1.5,1.5h7.4V11.4 c0-0.1-0.1-0.2-0.2-0.2h-6v-0.6H14c1.2,0,2.3,0,3.5,0c0.1,0,0.3-0.1,0.4-0.2c0.4-0.7,1.9-2.8,1.9-2.8H18.7z"
      />
      <path
        fill="#FFFFFF"
        d="M22.6,10.6C22.6,10.5,22.6,10.5,22.6,10.6l-1.9-3c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0l-1.8,2.8 c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1h1.7v0.6h-1.9c-0.1,0-0.2,0.1-0.2,0.2l0,15.2h2.7c0.8,0,1.5-0.7,1.5-1.5L22.6,10.6 C22.6,10.6,22.6,10.6,22.6,10.6z"
      />
    </g>
  </svg>
);

export default IconPinCarton;
