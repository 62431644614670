import PropTypes from "prop-types";
import locationShape from "./locationShape";

export default {
  ...locationShape,
  location_hours: PropTypes.arrayOf(PropTypes.object),
  website: PropTypes.string,
  phone: PropTypes.string,
  future_closures: PropTypes.arrayOf(PropTypes.object),
  served_zips: PropTypes.arrayOf(PropTypes.string),
  served_counties: PropTypes.arrayOf(PropTypes.string),
};
