import React from "react";
import PropTypes from "prop-types";
import { Checkbox as ReakitCheckbox } from "reakit/Checkbox";
import { IconChecked } from "../Icons";

import styles from "./Checkbox.module.scss";

const Checkbox = ({ children, value, checkboxProps }) => {
  const { state } = checkboxProps;

  return (
    <label className={styles.checkbox}>
      <ReakitCheckbox
        className={styles.checkbox__input}
        value={value}
        {...checkboxProps}
      />
      <IconChecked
        className={styles.checkbox__check}
        checked={state === value || state.includes(value)}
      />
      <span className={styles.checkbox__label}>{children}</span>
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  checkboxProps: PropTypes.object.isRequired,
};

export default Checkbox;
