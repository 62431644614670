import React from "react";

const IconPinBox = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31.3}
    height={40}
    data-name="Group 434"
    viewBox="0 0 173.289 215.912"
    {...props}
  >
    <path
      fill="currentColor"
      d="M151.512 0H21.775A21.777 21.777 0 0 0 0 21.777v125.431a21.777 21.777 0 0 0 21.775 21.776H55.61l29.026 45.806a2.412 2.412 0 0 0 4.057.029l29.952-45.834h32.866a21.777 21.777 0 0 0 21.777-21.777V21.777A21.777 21.777 0 0 0 151.512 0Z"
      data-name="Path 177"
    />
    <g data-name="Group 409">
      <path
        fill="#fff"
        d="M52.807 91.881a18.989 18.989 0 1 1 18.181-24.475l-3.7 1.116a15.12 15.12 0 1 0-14.475 19.491 14.94 14.94 0 0 0 8.92-2.919l2.294 3.116a18.79 18.79 0 0 1-11.22 3.671Z"
        data-name="Path 178"
      />
    </g>
    <g data-name="Group 410">
      <path
        fill="#fff"
        d="M41.985 74.814a1.934 1.934 0 0 1-1.924-1.757 12.738 12.738 0 0 1 8.912-13.867 1.935 1.935 0 0 1 1.049 3.724 8.937 8.937 0 0 0-6.109 9.788 1.933 1.933 0 0 1-1.748 2.1 1.757 1.757 0 0 1-.18.012Z"
        data-name="Path 179"
      />
    </g>
    <g data-name="Group 411">
      <path
        fill="#fff"
        d="M52.808 57.777a1.935 1.935 0 0 1-1.932-1.854 26.573 26.573 0 0 1 .568-6.67 22.3 22.3 0 0 1 4.754-10.055 1.935 1.935 0 0 1 2.974 2.475 18.479 18.479 0 0 0-3.94 8.35 22.838 22.838 0 0 0-.493 5.744 1.936 1.936 0 0 1-1.853 2.013c-.026-.003-.053-.003-.078-.003Z"
        data-name="Path 180"
      />
    </g>
    <g data-name="Group 412">
      <path
        fill="#fff"
        d="M44.793 53.624a10.574 10.574 0 0 1-3.713-.662c-5.436-2.021-7.554-7.958-7.642-8.21a1.935 1.935 0 0 1 1.315-2.5c11.865-3.261 17.43 2.941 18.716 6.678a1.935 1.935 0 0 1-.86 2.3 15.742 15.742 0 0 1-7.816 2.394Zm-6.7-8.161a9.088 9.088 0 0 0 4.361 3.881 8.588 8.588 0 0 0 6.536-.6c-1.314-1.774-4.411-4.389-10.901-3.281Z"
        data-name="Path 181"
      />
    </g>
    <g data-name="Group 413">
      <path
        fill="#fff"
        d="M104.511 55.406h-.132a1.935 1.935 0 0 1 0-3.869h.132a1.935 1.935 0 0 1 0 3.869Z"
        data-name="Path 182"
      />
    </g>
    <g data-name="Group 414">
      <path
        fill="#fff"
        d="M136.94 75.78a1.934 1.934 0 0 1-1.934-1.935V55.406h-21.24a1.935 1.935 0 0 1 0-3.869h23.175a1.934 1.934 0 0 1 1.935 1.934v20.374a1.934 1.934 0 0 1-1.936 1.935Z"
        data-name="Path 183"
      />
    </g>
    <g data-name="Group 415">
      <path
        fill="#fff"
        d="M136.935 65.593h-22.022a1.935 1.935 0 0 1 0-3.869h22.022a1.935 1.935 0 0 1 0 3.869Z"
        data-name="Path 184"
      />
    </g>
    <g data-name="Group 416">
      <path
        fill="#fff"
        d="M120.53 41.673a13.052 13.052 0 0 1-6.723-1.589 1.957 1.957 0 0 1-.371-.293l-4.1-4.14a1.935 1.935 0 0 1-.2-.24c-.951-1.337-2.448-4.361-1.783-9.587a21.655 21.655 0 0 1 2.259-5.94c1.017-2.1 2.171-4.471 2.189-5.94a1.935 1.935 0 0 1 3.678-.815 32.626 32.626 0 0 1 3.215 11.02c.979-.4 2.061-.774 3.174-1.154 2.2-.751 4.687-1.6 5.738-2.613a1.935 1.935 0 0 1 3.178 2 31.08 31.08 0 0 1-4.106 8.152 31.3 31.3 0 0 1 9.086 3.1 1.935 1.935 0 0 1-.895 3.65c-1.474 0-3.874 1.113-5.991 2.1a21.812 21.812 0 0 1-5.99 2.16 21.52 21.52 0 0 1-2.358.129Zm-4.529-4.79c.835.45 2.838 1.209 6.449.812a20.462 20.462 0 0 0 4.8-1.825c.589-.274 1.184-.549 1.778-.812a27.607 27.607 0 0 0-6.133-1.078 1.935 1.935 0 0 1-1.325-3.218 27.3 27.3 0 0 0 3.4-4.748c-.611.221-1.232.433-1.848.644a20.156 20.156 0 0 0-4.752 2.016c-.124.091-.2.153-.276.215a1.954 1.954 0 0 1-2.094.228 1.933 1.933 0 0 1-1.072-1.8 23.5 23.5 0 0 0-1-7.493c-.272.585-.556 1.17-.837 1.751a20.266 20.266 0 0 0-1.9 4.75c-.491 3.853.489 5.924 1.018 6.728Z"
        data-name="Path 185"
      />
    </g>
    <g data-name="Group 417">
      <path
        fill="#fff"
        d="M56.519 90.967a2.346 2.346 0 0 1-2.009-.943c-.935-1.215-.858-2.9 2.286-7.944a101.12 101.12 0 0 1 7.134-9.855 2.088 2.088 0 0 1 .218-.227 1.926 1.926 0 0 1 .194-.543c.52-.969 5.028-7.135 16.829-23.21l4.985-6.792a52.074 52.074 0 0 1 9.263-8.476 1.928 1.928 0 0 1 .724-.263c6.067-2.924 12-1.7 17.187 3.55 4.021 4.069 5.416 8.544 4.154 13.309a4.8 4.8 0 0 1-.18 1.048c-1.468 5.03-7.418 10.375-10.119 12.6-.845.99-3.056 2.337-10.118 6.1-.75.4-1.423.757-1.963 1.05l-2.6 1.466a1.921 1.921 0 0 0-1.337.761l-5.821 3.292-2.708 1.643-17.6 10.2-.095.053c-4.31 2.183-6.824 3.181-8.424 3.181Zm9.129-14.7a77.575 77.575 0 0 0-6.913 10.145c.987-.4 2.4-1.039 4.41-2.057l17.519-10.158 2.727-1.653 4.925-2.787a6.527 6.527 0 0 0-1.394-3.751 2.584 2.584 0 1 1 3.893-3.393 11.47 11.47 0 0 1 2.294 4.432l.125-.07c.575-.312 1.257-.675 2.016-1.079 2.2-1.172 7.062-3.759 8.615-4.885a22.384 22.384 0 0 0-5.343-13.868 1.935 1.935 0 0 1 2.936-2.52 27.133 27.133 0 0 1 4.342 6.9 21.844 21.844 0 0 1 1.762 6.191c2.752-2.632 5.269-5.662 6.012-8.121a1.926 1.926 0 0 1 .046-.56 9.032 9.032 0 0 0-2.047-3.776c-.108-.127-.217-.25-.328-.37a1.936 1.936 0 0 1 .1-2.729 1.888 1.888 0 0 1 1.346-.514 14.446 14.446 0 0 0-2.113-2.66c-3.253-3.293-6.606-4.519-10.188-3.7q.31.349.6.722a1.964 1.964 0 0 1-.223 2.64c-.5.451-1.55 1.008-2.963-.344q-.832-.8-1.4-1.286a52.64 52.64 0 0 0-7.129 6.729l-4.984 6.789c-6.862 9.347-13.405 18.261-15.71 21.531a18.871 18.871 0 0 1 1.565 1.5 2.551 2.551 0 0 1-.327 3.528 2.415 2.415 0 0 1-.129.1 2.589 2.589 0 0 1-3.585-.422q-.222-.254-.457-.501Zm24.016-12.985Z"
        data-name="Path 186"
      />
    </g>
    <g fill="#fff" data-name="Group 418">
      <path
        d="m93.454 75.709-.627 1.238L81.58 99.364l-54.235-.6v52.037l65.478 7.5 47.882-8.566V98.613l-38.976.6Zm1.7 70.577h-3.869v-41.784h3.869Z"
        data-name="Path 187"
      />
      <path
        stroke="#fff"
        d="m93.457 75.633 8.3 23.551 51.628-.8-13.114-25.53H92.478l.052.147H27.242L8.565 98.533l72.99.806Z"
        data-name="Path 188"
      />
    </g>
  </svg>
);

export default IconPinBox;
