import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useGlobal } from "frontend/hooks";
import styles from "./Sponsors.module.scss";
import { useTranslation } from "react-i18next";

const Sponsors = ({ isHidden, bgColor }) => {
  const { sponsors: sponsorsData } = useGlobal();

  const { t, i18n } = useTranslation();

  const renderSponsorLogo = (sponsor) => (
    <img
      className={styles.sponsors__img}
      src={sponsor.logo.path}
      type={sponsor.logo.content_type}
      alt={sponsor.alt_text[i18n.language] || sponsor.alt_text.en || ""}
      width="140px"
    />
  );

  const sponsors = sponsorsData?.sponsors ?? [];

  if (sponsors.length === 0) return null;

  return (
    <section
      className={classNames(
        styles.sponsors,
        "is-hidden-print",
        isHidden && "is-hidden-mobile",
        bgColor === "neutral10" && "a-bg-neutral10"
      )}
    >
      <div className={styles.sponsors__text}>
        {t("results.sponsor_tagline")}
      </div>
      <ul className={styles.sponsors__list}>
        {sponsors.map((sponsor) => (
          <li key={sponsor.id}>
            {sponsor.url.en ? (
              <a
                href={sponsor.url.en}
                target="_blank"
                rel="noopener noreferrer"
              >
                {renderSponsorLogo(sponsor)}
              </a>
            ) : (
              renderSponsorLogo(sponsor)
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

Sponsors.propTypes = {
  isHidden: PropTypes.bool,
  bgColor: PropTypes.string,
};

export default Sponsors;
