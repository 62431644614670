import React from "react";

const IconPinBowl = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 31.3 40"
    width="31.3"
    height="40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.2,1H4.1C2.2,1,0.6,2.6,0.6,4.5v22.6c0,1.9,1.6,3.4,3.4,3.4h6.4l5.3,8.6l5.3-8.6h6.4c1.9,0,3.4-1.6,3.4-3.4V4.5 C30.7,2.6,29.1,1,27.2,1z"
    />
    <g id="bowl">
      <path
        fill="#FFFFFF"
        d="M24.3,15.6c-2.2-2.5-6-2.8-8.5-0.6c0,0-0.1,0.1-0.1,0.1c-2.5-2.3-6.3-2.1-8.6,0.3 c0,0-0.1,0.1-0.1,0.1H3.4v0.7c0,6.3,5.1,11.4,11.4,11.4h1.7c6.3,0,11.4-5.1,11.4-11.4v-0.7L24.3,15.6z M8.5,15.6 c1.8-1.3,4.2-1.3,6,0H8.5z M19.7,14.6c1.1,0,2.1,0.4,3,1h-6C17.6,15,18.6,14.6,19.7,14.6z M18.7,25.6c-0.4,0.1-0.8,0.1-1.1,0.1 v-0.9c3,0,5.6-1.8,6.7-4.5l0.4,0.1l0.5,0.2C24.1,23.3,21.6,25.2,18.7,25.6L18.7,25.6z"
      />
      <path
        fill="#FFFFFF"
        d="M12.3,10.8l-0.8-0.5c0.7-0.9,0.7-2.1,0-3c-0.9-1.2-0.9-2.9,0.1-4.1l0.8,0.5c-0.7,0.9-0.7,2.1,0,3 C13.2,8,13.2,9.6,12.3,10.8z"
      />
      <path
        fill="#FFFFFF"
        d="M19.8,10.8l-0.8-0.5c0.7-0.9,0.7-2.1,0-3c-0.9-1.2-0.9-2.9,0.1-4.1l0.8,0.5c-0.7,0.9-0.7,2.1,0,3 C20.7,8,20.7,9.6,19.8,10.8z"
      />
      <path
        fill="#FFFFFF"
        d="M16.1,13.1l-0.8-0.5c0.7-0.9,0.7-2.1,0-3c-0.9-1.2-0.9-2.9,0.1-4.1l0.8,0.5c-0.7,0.9-0.7,2.1,0,3 C17,10.3,17,11.9,16.1,13.1z"
      />
    </g>
  </svg>
);

export default IconPinBowl;
