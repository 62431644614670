import { useState, useEffect } from "react";
import { userLocationApi } from "frontend/api";

function getLocationString({ latitude, longitude }) {
  if (!latitude || !longitude) return null;

  return `${latitude},${longitude}`;
}

export default function useUserLocation() {
  const [state, setState] = useState({
    isLoading: true,
    isError: false,
    locationString: "",
  });

  const onError = () => {
    setState({
      isLoading: false,
      isError: true,
    });
  };

  const onSuccess = ({ coords }) => {
    setState({
      isLoading: false,
      isError: false,
      locationString: getLocationString(coords),
    });
  };

  // Get the user's location on mount
  useEffect(() => {
    userLocationApi({ onSuccess, onError, timeout: 5000 });
  }, []);

  return state;
}
