import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "frontend/components/Modal";
import DOMPurify from "dompurify";

const AnnouncementModal = () => {
  const { t } = useTranslation();
  const [announcement, setAnnouncement] = useState(null);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    const el = document.getElementById("announcement");

    if (el && el.dataset.id) {
      const { id } = el.dataset;

      setAnnouncement({ id });

      try {
        const session = sessionStorage.getItem("hide_ofb_announcement" + id);
        setIsHidden(session);
      } catch (error) {
        setIsHidden(false);
      }
    }
  }, [setAnnouncement, setIsHidden]);

  const handleOnClose = () => {
    try {
      sessionStorage.setItem("hide_ofb_announcement" + announcement.id, true);
    } catch (error) {
      // eslint-disable-next-line
      console.warn("Session storage not supported by this browser", error);
    }
  };

  return (
    announcement &&
    !isHidden && (
      <Modal
        isAlert
        isVisibleOnInit
        title={t("title", { ns: "announcement" })}
        onClose={handleOnClose}
        ariaLabel="Announcement"
        disclosure={<div></div>}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t("body", { ns: "announcement" })),
          }}
        ></div>
      </Modal>
    )
  );
};

AnnouncementModal.propTypes = {};

export default AnnouncementModal;
