import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "frontend/components/Container";
import AnnouncementModal from "frontend/components/AnnouncementModal";
import { AppProvider } from "frontend/contexts";
import SEO from "frontend/components/SEO";
import { globalDataShape } from "frontend/shapes";

function App({ globalData, initFilters }) {
  return (
    <Router>
      <AppProvider globalData={globalData} initFilters={initFilters}>
        <SEO />
        <AnnouncementModal />
        <Container />
      </AppProvider>
    </Router>
  );
}

App.propTypes = {
  globalData: PropTypes.shape(globalDataShape),
  initFilters: PropTypes.object,
};

export default App;
