import { useEffect, useReducer } from "react";
import { locationsApi } from "frontend/api";
import { useGlobal } from "frontend/hooks";

const locationDefault = {};

function locationReducer(state, action) {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true, isError: false };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        location: action.payload,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        location: locationDefault,
      };
    default:
      throw new Error();
  }
}

export default function useLocationApi(id) {
  const { userLocation } = useGlobal();

  const [state, dispatch] = useReducer(locationReducer, {
    isLoading: false,
    isError: false,
    location: locationDefault,
  });

  useEffect(() => {
    const handleSuccess = (res) => {
      dispatch({ type: "FETCH_SUCCESS", payload: res });
    };

    const handleError = (error) => {
      dispatch({ type: "FETCH_FAILURE", payload: error });
    };

    dispatch({ type: "FETCH_INIT" });

    locationsApi({
      id,
      params: {
        user_location: userLocation || null,
      },
      onSuccess: handleSuccess,
      onError: handleError,
    });
  }, [id, userLocation]);

  return [state];
}
