import React from "react";
import PropTypes from "prop-types";

const BasicLink = ({ children, href, target, ...otherProps }) => {
  const rel = target === "_blank" ? "noopener noreferrer" : null;

  return (
    <a href={href} target={target} rel={rel} {...otherProps}>
      {children}
    </a>
  );
};

BasicLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
};

export default BasicLink;
