import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { useLocations } from "frontend/hooks";
import MapLegend from "../MapLegend";

const MapWrapper = ({
  googleKey,
  children,
  isHidden,
  containerClass,
  language,
}) => {
  const mapRef = useRef(null);
  const legendRef = useRef(null);
  const { zoom, center, setPosition } = useLocations();

  // Get the google map reference
  const handleLoad = useCallback(
    (map) => {
      mapRef.current = map;

      if (legendRef.current) {
        // eslint-disable-next-line
        mapRef.current.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
          legendRef.current
        );
      }
    },
    [mapRef, legendRef]
  );

  // Keep current zoom in sync with zoom state
  const handleZoom = useCallback(() => {
    if (mapRef && mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      if (zoom && newZoom !== zoom) {
        setPosition({ zoom: newZoom });
      }
    }
  }, [zoom, setPosition, mapRef]);

  return (
    <div
      className={classNames("is-hidden-print", containerClass, {
        "is-hidden-mobile": isHidden,
      })}
    >
      <MapLegend ref={legendRef} loaded={!!mapRef?.current} />
      <LoadScript
        googleMapsApiKey={googleKey}
        language={language}
        preventGoogleFontsLoading={false}
      >
        <GoogleMap
          id="circle-example"
          onLoad={handleLoad}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          center={center}
          zoom={zoom}
          onZoomChanged={handleZoom}
          language={language}
        >
          {children}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

MapWrapper.propTypes = {
  googleKey: PropTypes.string.isRequired,
  children: PropTypes.node,
  isHidden: PropTypes.bool,
  containerClass: PropTypes.string,
  language: PropTypes.string,
};

export default MapWrapper;
