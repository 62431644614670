import React, { useReducer, createContext } from "react";
import PropTypes from "prop-types";

function filtersReducer(state, action) {
  switch (action.type) {
    case "MOBILE_UPDATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
}

export const MobileContext = createContext();

export const MobileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filtersReducer, {
    view: "results",
    filtersOpen: false,
    searchOpen: true,
  });

  const setView = (view) => {
    dispatch({
      type: "MOBILE_UPDATE",
      payload: { view },
    });
  };

  const toggleFiltersOpen = () => {
    dispatch({
      type: "MOBILE_UPDATE",
      payload: {
        filtersOpen: !state.filtersOpen,
      },
    });
  };

  const closeFilters = () => {
    dispatch({
      type: "MOBILE_UPDATE",
      payload: {
        filtersOpen: false,
      },
    });
  };

  const toggleSearchOpen = () => {
    dispatch({
      type: "MOBILE_UPDATE",
      payload: {
        searchOpen: !state.searchOpen,
      },
    });
  };

  return (
    <MobileContext.Provider
      value={{
        ...state,
        setView,
        toggleFiltersOpen,
        closeFilters,
        toggleSearchOpen,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

MobileProvider.propTypes = {
  children: PropTypes.node,
};
