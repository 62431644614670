/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { IconPin } from "frontend/components/Icons";
import { useTranslation } from "react-i18next";
import styles from "./CategoriesList.module.scss";

const CategoriesList = ({
  categories,
  className,
  size = "lg",
  showDescriptions,
}) => {
  const { t } = useTranslation();

  if (!categories || categories.length === 0) return null;

  const iconSize = size === "sm" ? 20 : 30;

  return (
    <ul className={classNames(styles.categoriesList, className)}>
      {categories.map(({ id, icon, pin_color, pinColor }) => (
        <li
          key={id}
          className={classNames(styles.categoriesList__item, {
            [styles[`categoriesList__item--sm`]]: size === "sm",
          })}
        >
          <IconPin
            color={pinColor || pin_color}
            icon={icon}
            height={iconSize}
            width={iconSize}
          />
          <div className={styles.categoriesList__itemDescription}>
            <span className="t-bold">
              {t(`category_${id}`, { ns: "categories" })}
            </span>
            {showDescriptions && (
              <p>{t(`category_${id}_description`, { ns: "categories" })}</p>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

CategoriesList.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
  tag: PropTypes.string,
  size: PropTypes.oneOf(["sm", "lg"]),
  showDescriptions: PropTypes.bool,
};

export default CategoriesList;
