import React from "react";

const IconPinBag = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 31.3 40"
    width="31.3"
    height="40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      d="M27.2,1H4.1C2.2,1,0.6,2.6,0.6,4.5v22.6c0,1.9,1.6,3.4,3.4,3.4h6.4l5.3,8.6l5.3-8.6h6.4c1.9,0,3.4-1.6,3.4-3.4V4.5
  C30.7,2.6,29.1,1,27.2,1z"
    />
    <g id="bag">
      <rect x="12.7" y="4.2" fill="#FFFFFF" width="6" height="2.3" />
      <polygon fill="#FFFFFF" points="12.2,4.2 9.9,5.2 9.9,9.8 12.2,9.8 	" />
      <polygon fill="#FFFFFF" points="21.6,5.2 19.3,4.2 19.3,9.8 21.6,9.8 	" />
      <path
        fill="#FFFFFF"
        d="M7.4,10.4v9.3c0,3.6,2.9,6.5,6.5,6.5h3.6c3.6,0,6.5-2.9,6.5-6.5v-9.3H7.4z M14.6,25L14.6,25
    c-0.3,0-0.7-0.1-0.9-0.1c-2.4-0.4-4.3-2-5.1-4.2l0,0l0.8-0.3l0,0c0.8,2.3,2.9,3.8,5.3,3.9l0,0V25z"
      />
    </g>
  </svg>
);

export default IconPinBag;
