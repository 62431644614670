export default function userLocationApi({ onSuccess, onError, ...options }) {
  if (navigator && navigator.geolocation) {
    return navigator.geolocation.getCurrentPosition(
      onSuccess,
      onError,
      options
    );
  } else {
    onError({ message: "Geolocation is not supported by this browser." });
  }
}
