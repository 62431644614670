import React from "react";
import PropTypes from "prop-types";
import { MarkerClustererF as MarkerClusterer } from "@react-google-maps/api";
import { locationShape } from "frontend/shapes";
import { useLocations } from "frontend/hooks";
import MapMarker from "../MapMarker";

const MapLocations = () => {
  const { locations, markerId, setMarkerId } = useLocations();

  const handleMarkerClick = ({ id }) => {
    setMarkerId(id);
  };

  const handleInfoClose = () => {
    setMarkerId(0);
  };

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };

  if (!locations || locations.length === 0) return null;

  return (
    <MarkerClusterer options={options} maxZoom={14}>
      {(clusterer) =>
        locations.map((location) => (
          <MapMarker
            key={location.id}
            clusterer={clusterer}
            location={location}
            onClick={handleMarkerClick}
            onClose={handleInfoClose}
            showInfo={markerId === location.id}
          />
        ))
      }
    </MarkerClusterer>
  );
};

MapLocations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape(locationShape)),
};

export default MapLocations;
