import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocations, useProgress } from "frontend/hooks";

import styles from "./ProgressBar.module.scss";

const ProgressBar = ({ containerClass }) => {
  const { isLoading } = useLocations();
  const value = useProgress(isLoading);

  const barStyle = {
    width: `${value}%`,
  };

  return (
    <div
      role="progressbar"
      aria-label="Loading"
      aria-valuenow={value}
      aria-valuemin="0"
      aria-valuemax="100"
      className={classNames(containerClass, styles.progress)}
    >
      {isLoading && (
        <>
          <div className={styles.progress__bar} style={barStyle}></div>
          <div className="sr-only">{value}%</div>
        </>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  containerClass: PropTypes.string,
};

export default ProgressBar;
