import { useEffect, useState, useRef } from "react";

export default function useProgress(isLoading) {
  const [value, setValue] = useState(100);
  const timeoutRef = useRef();

  useEffect(() => {
    const step = 0.5;
    let current = 0;

    if (isLoading) {
      setValue(0);

      timeoutRef.current = setInterval(function () {
        current += step;
        const progress =
          Math.round((Math.atan(current) / (Math.PI / 2)) * 100 * 1000) / 1000;

        if (progress >= 100) {
          setValue(100);
          clearInterval(timeoutRef.current);
        } else {
          setValue(progress);
        }
      }, 100);
    } else {
      clearInterval(timeoutRef.current);
      setValue(100);
    }

    return () => {
      // Clean up any existing timeouts
      clearTimeout(timeoutRef.current);
    };
  }, [isLoading, setValue]);

  return value;
}
