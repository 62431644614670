import React from "react";
import PropTypes from "prop-types";
import ButtonOrLink from "./ButtonOrLink";

import styles from "./Link.module.scss";

const Link = ({ children, ...otherProps }) => (
  <ButtonOrLink className={styles.link} {...otherProps}>
    {children}
  </ButtonOrLink>
);

Link.propTypes = {
  children: PropTypes.node,
};

export default Link;
