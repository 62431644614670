import React from "react";
import PropTypes from "prop-types";
import { IconLocation } from "../Icons";

import styles from "./Distance.module.scss";

const Distance = ({ value }) => {
  return (
    <div className={styles.distance}>
      <IconLocation />
      {value}
    </div>
  );
};

Distance.propTypes = {
  value: PropTypes.string,
};

export default Distance;
