import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const Sidebar = ({ children, containerClass }) => {
  const { pathname } = useLocation();

  return (
    <section
      className={classNames(containerClass, {
        "is-hidden-print": location && pathname.includes("locations"),
      })}
    >
      {children}
    </section>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node,
  containerClass: PropTypes.string,
};

export default Sidebar;
