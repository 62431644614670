import React, {
  useEffect,
  useReducer,
  useCallback,
  createContext,
  useState,
} from "react";
import PropTypes from "prop-types";
import idx from "idx";
import { locationsApi } from "frontend/api";
import { useFilters, useDebounce } from "frontend/hooks";
import { isEmpty } from "frontend/helpers";
import { useUserLocation } from "frontend/hooks";

const DEFAULT_META = {
  center: null,
  zoom: null,
};

function locationsReducer(state, action) {
  const { payload } = action;
  const zoom = idx(payload, (_) => _.meta.zoom);
  const center = idx(payload, (_) => _.meta.center);
  const locations = idx(payload, (_) => _.locations);

  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true, isError: false };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        locations: locations || [],
        zoom: zoom || state.zoom,
        center: center || state.center,
      };
    case "FETCH_FAILURE":
      return { ...state, isLoading: false, isError: true, locations: [] };
    case "UPDATE_POSITION":
      return {
        ...state,
        center: center || state.center,
        zoom: zoom || state.zoom,
      };
    case "UPDATE_MARKER":
      return {
        ...state,
        markerId: payload.markerId,
      };
    default:
      throw new Error();
  }
}

export const LocationsContext = createContext();

export const LocationsProvider = ({ children }) => {
  // Debounce the filter state
  const { params } = useFilters();
  const { locationString, isLoading: locationIsLoading } = useUserLocation();
  const debouncedParams = useDebounce(params, 500, true);
  const controllerRef = useState(null);

  // Set the locations state
  const [state, dispatch] = useReducer(locationsReducer, {
    isLoading: true,
    isError: false,
    locations: [],
    ...DEFAULT_META,
  });

  // Get locations if the filter params have changed
  useEffect(() => {
    if (isEmpty(debouncedParams) || locationIsLoading) return;

    // If we're already loading locations, cancel request
    if (controllerRef.current) {
      console.debug("cancel request");
      controllerRef.current.abort();
    }

    const handleSuccess = (res) => {
      dispatch({ type: "FETCH_SUCCESS", payload: res });
    };

    const handleError = (error) => {
      dispatch({ type: "FETCH_FAILURE", payload: error });
    };

    dispatch({ type: "FETCH_INIT" });

    const { city_or_zip: cityOrZip, ...params } = debouncedParams;

    // Send either city_or_zip OR user_location
    const apiParams = {
      city_or_zip: isEmpty(cityOrZip) ? null : cityOrZip,
      user_location: isEmpty(cityOrZip) ? locationString : null,
      ...params,
      full_data: true,
    };

    // Create a new controller for the new request
    const controller = new AbortController();
    const signal = controller.signal;
    controllerRef.current = controller;

    locationsApi({
      params: apiParams,
      onSuccess: handleSuccess,
      onError: handleError,
      signal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams, locationIsLoading]);

  const setPosition = ({ center, zoom }) => {
    const payload = {
      meta: { center, zoom },
    };
    dispatch({ type: "UPDATE_POSITION", payload });
  };

  const setMarkerId = useCallback(
    (markerId) => {
      dispatch({ type: "UPDATE_MARKER", payload: { markerId } });
    },
    [dispatch]
  );

  const printFullData = useCallback(() => {
    const handleSuccess = (res) => {
      dispatch({ type: "FETCH_SUCCESS", payload: res });
      window.print();
    };

    const handleError = (error) => {
      dispatch({ type: "FETCH_FAILURE", payload: error });
    };

    dispatch({ type: "FETCH_INIT" });

    const { city_or_zip: cityOrZip, ...params } = debouncedParams;

    // Send either city_or_zip OR user_location
    const apiParams = {
      city_or_zip: isEmpty(cityOrZip) ? null : cityOrZip,
      user_location: isEmpty(cityOrZip) ? locationString : null,
      ...params,
      full_data: true,
    };

    locationsApi({
      params: apiParams,
      onSuccess: handleSuccess,
      onError: handleError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  return (
    <LocationsContext.Provider
      value={{ ...state, setPosition, setMarkerId, printFullData }}
    >
      {children}
    </LocationsContext.Provider>
  );
};

LocationsProvider.propTypes = {
  children: PropTypes.node,
};
