import React from "react";
import PropTypes from "prop-types";

const IconMenu = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 26.674}
      height={height || 20.053}
      viewBox="0 0 26.674 20.053"
    >
      <path d="M0 20.053v-3h26.674v3zm0-8.527v-3h26.674v3zM0 3V0h26.674v3z" />
    </svg>
  );
};

IconMenu.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default IconMenu;
