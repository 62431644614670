import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link as RouterLink } from "react-router-dom";
import { Button as ReakitButton } from "reakit/Button";
import BasicLink from "./BasicLink";

import styles from "./ButtonOrLink.module.scss";

const ButtonOrLink = ({
  className,
  children,
  icon,
  iconLeft,
  to,
  href,
  onClick,
  ...otherProps
}) => {
  const hasIcon = icon || iconLeft;

  if (to) {
    return (
      <RouterLink
        className={classNames(className, { [styles.buttonorlink]: hasIcon })}
        to={to}
        {...otherProps}
      >
        {iconLeft && <span>{iconLeft}</span>}
        <span>{children}</span>
        {icon && <span>{icon}</span>}
      </RouterLink>
    );
  } else if (onClick) {
    return (
      <ReakitButton
        className={classNames(className, { [styles.buttonorlink]: hasIcon })}
        onClick={onClick}
        {...otherProps}
      >
        {iconLeft && <span>{iconLeft}</span>}
        <span>{children}</span>
        {icon && <span>{icon}</span>}
      </ReakitButton>
    );
  } else if (href) {
    return (
      <BasicLink
        className={classNames(className, { [styles.buttonorlink]: hasIcon })}
        href={href}
        {...otherProps}
      >
        {iconLeft && <span>{iconLeft}</span>}
        <span>{children}</span>
        {icon && <span>{icon}</span>}
      </BasicLink>
    );
  }

  return null;
};

ButtonOrLink.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  iconLeft: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonOrLink;
