import format from "date-fns/format";
import parse from "date-fns/parse";
import getDateLocale from "./getDateLocale";

export default function getFormattedClosures(closures, lang) {
  const locale = getDateLocale(lang);

  function formatDate(date) {
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());

    return format(parsedDate, "PP", { locale });
  }

  return closures.map(({ start_date: start, end_date: end, comment }) => {
    let date = "";

    if (start === end) {
      date = formatDate(start);
    } else {
      date = `${formatDate(start)} - ${formatDate(end)}`;
    }

    return { date, comment };
  });
}
