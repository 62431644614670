import React from "react";
import PropTypes from "prop-types";

const IconClose = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.983 20.983"
    width={width || 20.983}
    height={height || 20.983}
  >
    <path d="M10.491 12.613l-8.37 8.37L0 18.862l8.37-8.37L0 2.122 2.121.001l8.37 8.37 8.37-8.37 2.121 2.121-8.37 8.37 8.37 8.37-2.121 2.121z" />
  </svg>
);

IconClose.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default IconClose;
