import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";

// Gets and set search query parameters
export default function useQueryFilters() {
  const location = useLocation();
  const history = useHistory();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      updateQuery: (values) => {
        const query = qs.parse(location.search);
        const newQuery = qs.stringify({ ...query, ...values });
        history.push(`?${newQuery}`);
      },
      clearQuery: () => {
        history.push(`?`);
      },
      // Parse query string into single "query" object
      query: qs.parse(location.search),
    };
  }, [history, location]);
}
