import React from "react";

const IconPinCart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31.3}
    height={40}
    data-name="Group 433"
    viewBox="0 0 173.29 215.913"
    {...props}
  >
    <path
      fill="currentColor"
      d="M151.513 0H21.775A21.777 21.777 0 0 0 0 21.777v125.431a21.777 21.777 0 0 0 21.775 21.777h33.836l29.026 45.806a2.412 2.412 0 0 0 4.057.029l29.952-45.835h32.866a21.777 21.777 0 0 0 21.777-21.777V21.777A21.777 21.777 0 0 0 151.513 0Z"
      data-name="Path 141"
    />
    <g data-name="Group 365">
      <g fill="#fff" data-name="Group 362">
        <path
          d="M74.28 123.809a8.61 8.61 0 0 1 2.693.433h-5.378a8.56 8.56 0 0 1 2.685-.433Z"
          data-name="Path 142"
        />
        <path
          d="M120.753 123.809a8.563 8.563 0 0 1 2.685.433h-5.37a8.56 8.56 0 0 1 2.685-.433Z"
          data-name="Path 143"
        />
        <path
          d="M143.557 120.799a3.441 3.441 0 0 1-3.443 3.443h-7.228a14.625 14.625 0 0 0-24.258 0H86.413a14.625 14.625 0 0 0-24.258 0H52.046L36.41 38.228l-21.302-7.112a3.437 3.437 0 0 1 2.167-6.523l25.179 8.38 3.289 18.114 9.858 54.22 2.19 12.048h82.323a3.441 3.441 0 0 1 3.443 3.444Z"
          data-name="Path 144"
        />
      </g>
      <g data-name="Group 363">
        <path
          fill="#fff"
          stroke="#fff"
          d="M86.413 124.243a14.627 14.627 0 1 0 2.5 8.171 14.628 14.628 0 0 0-2.5-8.171ZM74.28 141.019a8.6 8.6 0 1 1 8.612-8.6 8.616 8.616 0 0 1-8.612 8.6Z"
          data-name="Path 145"
        />
      </g>
      <g data-name="Group 364">
        <path
          fill="#fff"
          stroke="#fff"
          d="M132.886 124.243a14.626 14.626 0 1 0 2.5 8.171 14.628 14.628 0 0 0-2.5-8.171Zm-12.135 16.776a8.6 8.6 0 1 1 8.6-8.6 8.616 8.616 0 0 1-8.6 8.6Z"
          data-name="Path 146"
        />
      </g>
      <path
        fill="#fff"
        d="m48.752 51.088 9.858 54.22h84.512l7.746-54.22Zm38.148 42.6h-5.161V62.71H86.9Zm15.491 3.869H97.23V58.834h5.161Zm15.491-3.869h-5.16V62.71h5.161Z"
        data-name="Path 147"
      />
    </g>
  </svg>
);

export default IconPinCart;
