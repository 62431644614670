import React from "react";
import PropTypes from "prop-types";
import { Checkbox as ReakitCheckbox } from "reakit/Checkbox";
import { useTranslation } from "react-i18next";
import AttributeIcon from "../AttributeIcon";

import styles from "./AttributeFilter.module.scss";

const AttributeFilter = ({ labelKey, value, icon, checkboxProps }) => {
  const { state } = checkboxProps;
  const { t } = useTranslation();

  return (
    <label className={styles.checkbox}>
      <ReakitCheckbox
        className={styles.checkbox__input}
        value={value}
        {...checkboxProps}
      />
      <span className={styles.checkbox__check}>
        <AttributeIcon icon={icon} large />
      </span>
      <span className={styles.checkbox__label}>
        {t(labelKey, { ns: "attributes" })}
      </span>
    </label>
  );
};

AttributeFilter.propTypes = {
  labelKey: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  icon: PropTypes.string,
  checkboxProps: PropTypes.object,
};

export default AttributeFilter;
