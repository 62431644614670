import React from "react";
import PropTypes from "prop-types";

import styles from "./AttributeIcon.module.scss";

const AttributeIcon = ({ icon, large }) => {
  if (!icon) return null;

  return (
    <div className={styles.attributeIcon}>
      <img src={icon} width={large ? 50 : 25} alt="" />
    </div>
  );
};

AttributeIcon.propTypes = {
  large: PropTypes.bool,
  icon: PropTypes.string,
};

export default AttributeIcon;
