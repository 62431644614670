/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGlobal } from "frontend/hooks";
import { Button } from "frontend/components/ButtonOrLink";
import { useFilters } from "frontend/hooks";

import styles from "./MapLegend.module.scss";
import CategoriesList from "../../CategoriesList";

const MapLegend = React.forwardRef(({ loaded }, ref) => {
  const [hidden, setHidden] = useState(true);
  const {
    params: { active_campaign },
  } = useFilters();
  const { categories: defaultCategories, activeCampaign } = useGlobal();
  const { t } = useTranslation();

  const onClick = () => {
    setHidden(!hidden);
  };

  const categories = active_campaign
    ? activeCampaign?.categories ?? []
    : defaultCategories ?? [];

  return (
    <div
      ref={ref}
      className={styles.mapLegend}
      aria-live="polite"
      data-loaded={!!loaded}
    >
      {!!categories && categories.length >= 1 ? (
        <>
          <div
            className={styles.mapLegend__list}
            id="legend"
            aria-hidden={hidden}
            aria-labelledby="legendButton"
          >
            <CategoriesList
              categories={categories}
              size="sm"
              showDescriptions
            />
          </div>
          <div
            id="legendButton"
            className={styles.mapLegend__button}
            aria-controls="legend"
          >
            <Button onClick={onClick} type="button">
              {t(hidden ? "map.show_legend" : "map.hide_legend")}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
});

MapLegend.displayName = "MapLegend";

MapLegend.propTypes = {
  loaded: PropTypes.bool,
};

export default MapLegend;
