import React, { useEffect, useCallback, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useCheckboxState } from "reakit/Checkbox";
import { Button } from "../ButtonOrLink";
import MobileToggle from "../MobileToggle";
import T from "../Translate";
import FiltersSearch from "./FiltersSearch";
import FiltersCategory from "./FiltersCategory";
import FiltersDistance from "./FiltersDistance";
import FiltersOpen from "./FiltersOpen";
import FiltersAttributes from "./FiltersAttributes";
import { useTranslation } from "react-i18next";
import {
  useFilters,
  useMobile,
  useGlobal,
  useQueryFilters,
} from "frontend/hooks";
import { filterShape } from "frontend/shapes";
import FiltersViewResults from "./FiltersViewResults";

import styles from "./Filters.module.scss";
import FiltersCampaign from "./FiltersCampaign";

const Filters = () => {
  const {
    categories: defaultCategories,
    attributes,
    activeCampaign,
  } = useGlobal();
  const { t } = useTranslation();
  const { params, setFilters, resetFilters } = useFilters();
  const { filtersOpen } = useMobile();
  const { query, updateQuery } = useQueryFilters();
  // States
  const openDates = useCheckboxState({ state: [] });
  const openTimes = useCheckboxState({ state: [] });
  const attributesState = useCheckboxState({ state: [] });
  const formEl = useRef();
  const hasCampaign = !!activeCampaign;

  const categories = useMemo(() => {
    const campaignCategories = activeCampaign?.categories ?? [];

    return query.campaign ? campaignCategories : defaultCategories;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.campaign]);

  useEffect(() => {
    handleDateChange(openDates.state);
  }, [openDates.state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleTimeChange(openTimes.state);
  }, [openTimes.state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleFilterChange(attributesState.state);
  }, [attributesState.state]); // eslint-disable-line react-hooks/exhaustive-deps

  // Clear checkbox states if filters are reset
  useEffect(() => {
    if (params.reset) {
      openDates.setState([]);
      openTimes.setState([]);
      attributesState.setState([]);
      formEl.current.reset();
    }
  }, [params.reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDistanceChange = useCallback(
    (item) => {
      setFilters({
        distance: item.value,
      });
    },
    [setFilters]
  );

  const handleOnSearch = useCallback(
    (value) => {
      updateQuery({ q: value });
    },
    [updateQuery]
  );

  const handleDateChange = useCallback(
    (value) => {
      if (value.length > 0) {
        setFilters({ open_days: value });
      } else if (params.open_days && params.open_days.length !== 0) {
        setFilters({ open_days: null });
      }
    },
    [setFilters, params.open_days]
  );

  const handleTimeChange = useCallback(
    (value) => {
      if (value.length > 0) {
        const time = `${value[0]}:01`;
        setFilters({ open_at_time: time });
      } else if (params.open_at_time && params.open_at_time.length !== 0) {
        setFilters({ open_at_time: null });
      }
    },
    [setFilters, params.open_at_time]
  );

  const handleFilterChange = useCallback(
    (value) => {
      if (value.length > 0) {
        setFilters({ location_attributes: value });
      } else if (
        params.location_attributes &&
        params.location_attributes.length !== 0
      ) {
        setFilters({ location_attributes: null });
      }
    },
    [setFilters, params.location_attributes]
  );

  const handleReset = useCallback(() => {
    formEl.current.reset();
    resetFilters();
  }, [resetFilters]);

  const handleCampaignChange = useCallback(
    (value) => {
      updateQuery({ campaign: value !== "0" ? value : undefined });
    },
    [updateQuery]
  );

  return (
    <form
      aria-label={t("filters.aria_header")}
      className={classNames(styles.filters, "is-hidden-print")}
      id="filters"
      ref={formEl}
    >
      <FiltersSearch onSearch={handleOnSearch} defaultValue={query.q} />
      <MobileToggle containerClass={styles.filters__toggle} />
      <div
        className={classNames(
          {
            "is-hidden-mobile": !filtersOpen,
          },
          styles.filters__wrapper
        )}
      >
        <h3 className={styles.filters__header}>{t("filters.header")}</h3>
        {hasCampaign && (
          <div className={styles.filters__group}>
            <FiltersCampaign
              onChange={handleCampaignChange}
              isCampaign={!!query.campaign}
            />
          </div>
        )}
        <div
          className={classNames(
            styles.filters__dropdowns,
            styles.filters__group
          )}
        >
          {categories && (
            <FiltersCategory
              categories={categories}
              isCampaign={!!query.campaign}
            />
          )}
          <FiltersDistance onDistanceChange={handleDistanceChange} />
        </div>
        <div className={styles.filters__group}>
          <FiltersOpen
            dateCheckboxState={openDates}
            timeCheckboxState={openTimes}
          />
        </div>
        {attributes && attributes.length > 0 && (
          <div className={styles.filters__group}>
            <FiltersAttributes
              checkboxState={attributesState}
              attributes={attributes}
            />
          </div>
        )}
        <div
          className={classNames(styles.filters__reset, styles.filters__group)}
        >
          <Button onClick={handleReset} type="reset" secondary>
            <T translate="filters.reset_filters_button" />
          </Button>
          <FiltersViewResults />
        </div>
        <div className={classNames(styles.filters__help, `is-hidden-print`)}>
          <T translate="header.click_here_for_assistance" isHTML as="div" />
          <T translate="header.click_here_for_assistance_2" isHTML as="div" />
          <T translate="header.click_here_for_assistance_3" isHTML as="div" />
        </div>
      </div>
    </form>
  );
};

Filters.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.shape(filterShape)),
  categories: PropTypes.arrayOf(PropTypes.shape(filterShape)),
};

export default Filters;
