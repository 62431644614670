import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "../ButtonOrLink";
import { IconArrow } from "../Icons";
import { useLocations, useMobile } from "frontend/hooks";

import styles from "./FiltersSearch.module.scss";

const FiltersSearch = ({ onSearch, defaultValue }) => {
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const { isLoading } = useLocations();
  const { searchOpen, toggleSearchOpen } = useMobile();

  const handleOnClick = (event) => {
    event.preventDefault();

    const value = inputEl.current.value;
    if (onSearch) {
      onSearch(value);
      toggleSearchOpen();
    }
  };

  const handleSearchToggle = (event) => {
    event.preventDefault();
    toggleSearchOpen();
  };

  return (
    <>
      <div
        className={styles.filtersSearch}
        aria-live="polite"
        data-open={searchOpen}
      >
        <div aria-hidden={!searchOpen}>
          <label htmlFor="searchInput" className="sr-only">
            {t("filters.city_or_zip_label")}
          </label>
          <div className={styles.filtersSearch__inputWrapper}>
            <input
              ref={inputEl}
              type="text"
              className={styles.filtersSearch__input}
              id="searchInput"
              size="5"
              placeholder={t("filters.city_or_zip_placeholder")}
              defaultValue={defaultValue}
            />
            <Button type="submit" onClick={handleOnClick} disabled={isLoading}>
              <span className={styles.filtersSearch__buttonLabel}>
                {t("filters.search_aria_label")}
              </span>
            </Button>
          </div>
        </div>
      </div>
      <div
        hidden={searchOpen}
        className={classNames(styles.filtersSearch__searchAgain)}
      >
        <Button type="button" onClick={handleSearchToggle} theme block>
          <span>{t("filters.search_again_button")}</span>
          <IconArrow small />
        </Button>
      </div>
    </>
  );
};

FiltersSearch.propTypes = {
  onSearch: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default FiltersSearch;
