import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import {
  useDialogState,
  Dialog,
  DialogDisclosure,
  DialogBackdrop,
} from "reakit/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "../ButtonOrLink";
import { IconClose } from "../Icons";

import styles from "./Modal.module.scss";

const Modal = ({
  disclosure,
  title,
  isAlert,
  children,
  isVisibleOnInit,
  onClose,
  ariaLabel,
}) => {
  // Only set the initial visible state by creating a new boolean
  // Visible state after this should only be handled by the close button
  // and the disclosure node
  const dialog = useDialogState({ visible: Boolean(isVisibleOnInit) });
  const { t } = useTranslation();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }

    dialog.hide();
  };

  return (
    <>
      {disclosure && (
        <DialogDisclosure
          {...dialog}
          ref={disclosure.ref}
          {...disclosure.props}
        >
          {(disclosureProps) => cloneElement(disclosure, disclosureProps)}
        </DialogDisclosure>
      )}
      <DialogBackdrop className={styles.modal__backdrop} {...dialog}>
        <Dialog
          role={isAlert ? "alertdialog" : "dialog"}
          className={styles.modal}
          aria-label={ariaLabel}
          {...dialog}
        >
          <div className={styles.modal__button}>
            <Button
              secondary
              aria-label={t("menu.dismiss_button")}
              onClick={handleOnClose}
            >
              <IconClose />
            </Button>
          </div>
          <h2 className={styles.modal__header}>{title}</h2>
          <div className={styles.modal__content}>{children}</div>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  disclosure: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isVisibleOnInit: PropTypes.bool,
  isAlert: PropTypes.bool,
  onClose: PropTypes.func,
  ariaLabel: PropTypes.string.isRequired,
};

export default Modal;
