import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const IconArrow = ({ className, large, small, rotation }) => {
  const { i18n } = useTranslation();
  // Flip the arrow horizontally if text reads right to left.
  const scale = i18n.dir() === "rtl" ? -1 : 1;
  const style = {
    transform: `rotate(${rotation}deg) scale(${scale})`,
  };

  return large ? (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.9 12.9"
      width="21.9"
      height="12.9"
      style={style}
    >
      <path
        d="M11 12.9L0 2.4 2.6-.1l8.3 8L19.1 0h.4l2.4 2.4L11 12.9z"
        fill="#333"
      />
    </svg>
  ) : small ? (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12.5"
      height="7.5"
      viewBox="0 0 12.5 7.5"
      style={style}
    >
      <path
        className="st0"
        d="M6.3 7.5L0 1.5 1.8-.2l4.5 4.3 4.5-4.3 1.8 1.7-6.3 6z"
      />
    </svg>
  ) : (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="15.249"
      height="8.714"
      viewBox="0 0 15.249 8.714"
      style={style}
    >
      <path d="M13.818 0L7.625 5.961 1.43 0 0 1.376l7.625 7.338 7.625-7.338L13.821 0z" />
    </svg>
  );
};

IconArrow.propTypes = {
  className: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  rotation: PropTypes.number,
};

export default IconArrow;
