import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { locationShape } from "../../../shapes";
import Location from "../../Location";
import T from "../../Translate";

import styles from "./ResultsList.module.scss";

const ResultsList = ({ locations, isLoading, isError, isHidden }) => {
  return (
    <div
      className={classNames(styles.resultslist, {
        "is-hidden-mobile": isHidden,
      })}
    >
      {isLoading ? (
        <></>
      ) : isError ? (
        <div className={styles.resultslist__message}>
          <T translate="results.error_message" />
        </div>
      ) : locations.length === 0 ? (
        <div className={styles.resultslist__message}>
          <T translate="results.no_results_message" />
        </div>
      ) : (
        <ul>
          {locations.map((location) => (
            <Location key={location.id} location={location} />
          ))}
        </ul>
      )}
    </div>
  );
};

// TODO: Define a location object
ResultsList.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape(locationShape)),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isHidden: PropTypes.bool,
};

export default ResultsList;
