import locales from "./locales.json";

// Set the language label and key for the language dropdown options
const options = Object.entries(locales).map(([key, obj]) => {
  return {
    label: obj.label,
    value: key,
  };
});

export { options, locales };
