import React from "react";
import PropTypes from "prop-types";
import { globalDataShape } from "frontend/shapes";

import { FiltersProvider } from "./Filters";
import { LocationsProvider } from "./Locations";
import { MobileProvider } from "./Mobile";
import { GlobalProvider } from "./Global";

const AppProvider = ({ children, globalData, initFilters }) => (
  <GlobalProvider value={globalData}>
    <FiltersProvider initFilters={initFilters}>
      <LocationsProvider>
        <MobileProvider>{children}</MobileProvider>
      </LocationsProvider>
    </FiltersProvider>
  </GlobalProvider>
);

AppProvider.propTypes = {
  children: PropTypes.node,
  globalData: PropTypes.shape(globalDataShape),
  initFilters: PropTypes.object,
};

export default AppProvider;
