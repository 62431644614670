import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-named-as-default
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useGlobal } from "frontend/hooks";

const DetailSEO = ({ name }) => {
  const { t } = useTranslation();
  const {
    metaData: { title, description },
  } = useGlobal();

  return (
    <Helmet title={t(title, { pre: name + " - " })}>
      <meta property="og:title" content={t(title, { pre: name + " - " })} />
    </Helmet>
  );
};

DetailSEO.propTypes = {
  name: PropTypes.string,
};

export default DetailSEO;
