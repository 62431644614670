import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import AttributeIcon from "../AttributeIcon";
import Tooltip from "../Tooltip";

import styles from "./AttributeList.module.scss";

const AttributeList = ({ attributes, large, alignRight }) => {
  const { t, i18n } = useTranslation();

  if (!Array.isArray(attributes) || attributes.length === 0) return null;

  return (
    <ul
      aria-label="Attributes"
      className={classNames(styles.attributeslist, {
        [styles["attributeslist--right"]]: alignRight,
        [styles["attributeslist--large"]]: large,
      })}
    >
      {attributes.map(({ id, icon }, i) => (
        <li key={i} className={styles.attributeslist__item}>
          <Tooltip text={t(`attribute_${id}`, { ns: "attributes" })}>
            <AttributeIcon large={large || false} icon={icon} />
          </Tooltip>
        </li>
      ))}
    </ul>
  );
};

AttributeList.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  large: PropTypes.bool,
  alignRight: PropTypes.bool,
};

export default AttributeList;
