import PropTypes from "prop-types";

export default {
  id: PropTypes.string,
  distance: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  location_categories: PropTypes.arrayOf(PropTypes.object),
  location_attributes: PropTypes.arrayOf(PropTypes.object),
  location_hours: PropTypes.arrayOf(PropTypes.object),
  comment: PropTypes.string,
  phone: PropTypes.string,
  served_counties: PropTypes.arrayOf(PropTypes.string),
  served_zips: PropTypes.arrayOf(PropTypes.string),
  website: PropTypes.string,
};
