import React from "react";

const IconRadio = ({ ...svgProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 16 18"
    fill="transparent"
    {...svgProps}
  >
    <rect
      width={16}
      height={16}
      y={1.78}
      fill="currentColor"
      rx={8}
      stroke="#707070"
      strokeWidth={1}
    />
    <rect width={7} height={7} x={4.5} y={6.28} fill="#FFF" rx={3.5} />
  </svg>
);

export default IconRadio;
