import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { filterShape } from "frontend/shapes";
import { MultiSelect } from "../Dropdown";
import { useFilters } from "frontend/hooks";

import styles from "./Filters.module.scss";

const FiltersCategory = ({ categories, isCampaign }) => {
  const { t } = useTranslation();
  const { setFilters } = useFilters();

  const handleChange = useCallback(
    (value) => {
      setFilters({ location_categories: value });
    },
    [setFilters]
  );

  return (
    <div className={styles.filters__category}>
      <label htmlFor="categoryDropdown" className={styles.filters__label}>
        {isCampaign
          ? t("campaign_location_category_label", { ns: "active_campaign" })
          : t("filters.category_label")}
      </label>
      <MultiSelect
        large
        id="categoryDropdown"
        items={categories}
        ariaLabel={t("filters.category_dropdown_aria_label")}
        onChange={handleChange}
        placeholder={t("filters.category_any_label")}
        disabled={!categories || categories.length === 0}
      />
    </div>
  );
};

FiltersCategory.propTypes = {
  onCategoryChange: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape(filterShape)),
  isCampaign: PropTypes.bool,
};

export default FiltersCategory;
