import React from "react";
import { useTranslation } from "react-i18next";
import T from "../Translate";

import styles from "./BackToSite.module.scss";

const BackToSite = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.backToSite}>
      <T translate="menu.back_to_site_button" isHTML />
    </div>
  );
};

BackToSite.propTypes = {};

export default BackToSite;
