import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  MarkerF as Marker,
  InfoWindowF as InfoWindow,
} from "@react-google-maps/api";
import idx from "idx";
import MapInfo from "../MapInfo";
import { locationShape } from "frontend/shapes";
import { useGlobal } from "frontend/hooks";
import { IconPin } from "frontend/components/Icons";

import ReactDOMServer from "react-dom/server";

const MapMarker = ({ location, clusterer, onClick, onClose, showInfo }) => {
  const { themeColor } = useGlobal();

  const handleMarkerClick = () => {
    if (onClick) {
      onClick(location);
    }
  };

  const handleOnClose = () => {
    if (onClose) {
      onClose(location);
    }
  };

  const iconUrl = useMemo(() => {
    const color =
      idx(location.location_categories, (_) => _[0].pin_color) || themeColor;
    const icon = idx(location.location_categories, (_) => _[0].icon);
    const iconString = ReactDOMServer.renderToString(
      <IconPin color={color} icon={icon} />
    );
    return encodeURIComponent(iconString.replace("currentColor", color));
  }, [themeColor, location.location_categories]);

  if (typeof google === "undefined") return null;

  const position = { lat: location.latitude, lng: location.longitude };

  return (
    <Marker
      position={position}
      clusterer={clusterer}
      onClick={handleMarkerClick}
      icon={{
        url: `data:image/svg+xml,${iconUrl}`,
      }}
    >
      {showInfo && (
        <InfoWindow onCloseClick={handleOnClose} position={position}>
          <MapInfo location={location} />
        </InfoWindow>
      )}
    </Marker>
  );
};

MapMarker.propTypes = {
  location: PropTypes.shape(locationShape).isRequired,
  clusterer: PropTypes.object,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  showInfo: PropTypes.bool,
};

export default MapMarker;
