import React from "react";
import PropTypes from "prop-types";

const IconOpenClose = ({ isOpen }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <g transform="translate(-425.5 2467.5)">
        <path className="st0" d="M425.5-2463.2h10v1.5h-10z" />
        <path
          className="st0"
          d="M429.8-2467.5h1.5v10h-1.5z"
          style={{ display: isOpen ? "none" : "block" }}
        />
      </g>
    </svg>
  );
};

IconOpenClose.propTypes = {
  isOpen: PropTypes.bool,
};

export default IconOpenClose;
