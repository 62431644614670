import React from "react";
import PropTypes from "prop-types";
import IconPinDefault from "./IconPinDefault";
import IconPinBag from "./IconPinBag";
import IconPinBowl from "./IconPinBowl";
import IconPinCarton from "./IconPinCarton";
import IconPinBox from "./IconPinBox";
import IconPinCart from "./IconPinCart";
import IconPinStand from "./IconPinStand";

const IconPin = ({ color, width, height, icon, ...otherProps }) => {
  const iconStyle = {
    color: color || "#000000",
    width: width || 36.3,
    height: height || 35.3,
    ...otherProps,
  };

  let Icon = IconPinDefault;

  switch (icon) {
    case "bag.svg":
      Icon = IconPinBag;
      break;
    case "bowl.svg":
      Icon = IconPinBowl;
      break;
    case "carton.svg":
      Icon = IconPinCarton;
      break;
    case "box.svg":
      Icon = IconPinBox;
      break;
    case "cart.svg":
      Icon = IconPinCart;
      break;
    case "stand.svg":
      Icon = IconPinStand;
      break;
    default:
      break;
  }

  return <Icon style={iconStyle} />;
};

IconPin.displayName = "IconPin";

IconPin.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  icon: PropTypes.oneOf([
    "bag.svg",
    "bowl.svg",
    "carton.svg",
    "default.svg",
    "box.svg",
    "cart.svg",
    "stand.svg",
  ]),
};

export default IconPin;
