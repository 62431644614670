import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocations } from "frontend/hooks";
import { Link } from "../../ButtonOrLink";
import { IconSpinner } from "../../Icons";
import T from "../../Translate";

import styles from "./ResultsHeader.module.scss";

const ResultsHeader = ({ count, isHidden, isLoading }) => {
  const { printFullData } = useLocations();

  return (
    <header
      className={classNames(styles.resultsheader, {
        "is-hidden-mobile": isHidden,
      })}
    >
      {isLoading ? (
        <div className={styles.resultsheader__title}>
          <IconSpinner />
        </div>
      ) : (
        <h3 className={styles.resultsheader__title} aria-live="polite">
          <T translate="results.location_count" count={count} />
        </h3>
      )}
      {count > 0 && (
        <>
          <span
            className={classNames(
              "is-hidden-print",
              styles.resultsheader__backtofilters
            )}
          >
            <Link href="#filters">
              <T translate="results.back_to_filters" />
            </Link>
          </span>
          <span className="is-hidden-print">
            <Link onClick={printFullData}>
              <T translate="results.print_button" />
            </Link>
          </span>
        </>
      )}
    </header>
  );
};

ResultsHeader.propTypes = {
  count: PropTypes.number,
  isHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ResultsHeader;
