import React from "react";
import { useTranslation } from "react-i18next";
import { useLocations, useMobile } from "frontend/hooks";
import { Button } from "../../ButtonOrLink";
import { IconSpinner } from "../../Icons";

import styles from "./FiltersViewResults.module.scss";

function FiltersViewResults() {
  const { locations, isLoading } = useLocations();
  const { t } = useTranslation();
  const { toggleFiltersOpen } = useMobile();

  function handleClick(e) {
    e.preventDefault();
    toggleFiltersOpen();
  }

  return (
    <div className="is-hidden-desktop">
      <span className={styles.filtersViewResults__loading} hidden={!isLoading}>
        <IconSpinner size={15} />
      </span>
      <Button onClick={handleClick} type="submit">
        <span>{t("filters.view_results", { count: locations.length })}</span>
      </Button>
    </div>
  );
}

FiltersViewResults.propTypes = {};

export default FiltersViewResults;
