import { arDZ, enUS, es, ru, vi, uk, fr, zhCN, zhTW } from "date-fns/locale";

const locales = {
  ar: arDZ,
  en: enUS,
  es,
  ru,
  vi,
  "zh-Hans": zhCN,
  uk,
  fr,
  "zh-Hant": zhTW,
};

export default function getDateLocale(lang) {
  return locales[lang] ? locales[lang] : locales.en;
}
