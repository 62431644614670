import React from "react";

const IconPinDefault = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 36.3 45"
    width="31.3"
    height="40"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="currentColor"
      d="M31.6,0.5H4.7c-2.2,0-4,1.8-4,4v26.2c0,2.2,1.8,4,4,4h7.4l6.1,10l6.1-10h7.4c2.2,0,4-1.8,4-4V4.5 C35.6,2.3,33.8,0.5,31.6,0.5z"
    />
    <path
      id="carrot"
      fill="#FFFFFF"
      d="M26.4,12.1c0.9-0.3,2.4-1.7,3.5-1.9c-1.2-0.4-2.5-0.5-3.7-0.4l0,0c0.7-1.1,1.1-2.1,1.3-3.3
	c-0.7,0.9-2.7,1.5-3.2,2.1l-0.1,0.1c-0.3-1.5-0.9-2.8-1.7-3.9c0.1,1.1-0.8,2.9-0.8,3.9c0,0.8,0.3,1.5,0.5,2.1
	c-1.6-0.8-3.1-0.5-4.3,0.1c0.4,0.3,0.8,0.4,1.1,0.7c0.1,0,0.1,0.1,0,0.3l0,0c-0.1,0.1-0.3,0.1-0.3,0c-0.3-0.3-0.8-0.5-1.2-0.7
	c-0.9,0.8-1.6,1.5-2.1,2.5c-0.4,0.9-2.7,5.3-3.2,6.5c-0.7,1.1-1.5,2.8-1.5,2.8c0.3,0.1,0.5,0.3,0.8,0.5c0.1,0,0.1,0.1,0,0.3l0,0
	c-0.1,0.1-0.3,0.1-0.3,0c-0.3-0.3-0.4-0.3-0.7-0.4c-1.3,2.1-2.7,4.4-2.3,4.7c0.3,0.3,1.3-0.3,2.5-1.1l5.2-3.5l0.8-0.5l1.7-1.2
	c-0.1-0.8-0.5-1.3-1.1-1.7c-0.1,0-0.1-0.1-0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0c0.7,0.4,1.1,1.1,1.2,1.9l0.4-0.3l2.3-1.3
	c0.3-0.3,0.7-0.5,0.9-0.9c-0.1-1.1-0.5-2.1-1.3-2.9c-0.5-0.7-1.1-1.2-1.7-1.7c-0.1,0-0.1-0.1-0.1-0.3l0,0c0.1-0.1,0.3-0.1,0.3,0
	c0.7,0.5,1.3,1.1,1.9,1.7c0.7,0.8,1.1,1.7,1.3,2.8c0.9-0.9,1.7-2.1,2.3-3.2c-0.3-0.8-0.9-1.5-1.5-2.1c-0.1,0-0.1-0.1-0.1-0.3l0,0
	c0.1-0.1,0.3-0.1,0.3,0c0.5,0.4,1.1,0.9,1.5,1.6c0.1-0.9-0.3-1.6-0.9-2.5C25.1,12.3,25.7,12.3,26.4,12.1z"
    />
  </svg>
);

export default IconPinDefault;
