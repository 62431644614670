import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMobile, useGlobal } from "frontend/hooks";
import MapLocations from "./MapLocations";
import MapWrapper from "./MapWrapper";

const Map = ({ containerClass }) => {
  const { googleKey } = useGlobal();
  const { view, filtersOpen } = useMobile();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <MapWrapper
      googleKey={googleKey}
      isHidden={view !== "map" || filtersOpen}
      containerClass={containerClass}
      language={language}
    >
      <MapLocations />
    </MapWrapper>
  );
};

Map.propTypes = {
  containerClass: PropTypes.string,
};

export default Map;
