// react-i18next extends i18next's functionality
// params in this context are values passed into the translation key
// For docs on pluralization, see: https://www.i18next.com/translation-function/plurals
// See location_results_count for an example
import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

const T = React.memo(
  ({ translate, i18nKey, count, params, isHTML, as = "span" }) => {
    const { t } = useTranslation();

    const Tag = as;

    const value = t(translate);

    // Checks if the translation exists.
    // If the value = the translation key, it's not defined.
    if (value === translate) return null;

    return i18nKey ? (
      <Trans i18nKey={i18nKey} />
    ) : translate && isHTML ? (
      <Tag
        dangerouslySetInnerHTML={{
          __html: t(translate, {
            count,
            interpolation: { escapeValue: false },
            ...params,
          }),
        }}
      />
    ) : translate ? (
      t(translate, { count, ...params })
    ) : null;
  }
);

T.propTypes = {
  translate: PropTypes.string,
  i18nKey: PropTypes.string,
  count: PropTypes.number,
  params: PropTypes.object,
  isHTML: PropTypes.bool,
  as: PropTypes.oneOf(["span", "div"]),
};

T.displayName = "Translate";

export default T;
