import apiBase from "./base";

export default function locationsApi({
  params,
  id,
  onSuccess,
  onError,
  signal,
}) {
  const pathId = id ? `/${id}` : "";
  const path = `/api/v1/locations${pathId}`;
  const headers = { "Content-Type": "application/json" };
  return apiBase({ path, headers, params, onSuccess, onError, signal });
}
