import React from "react";
import PropTypes from "prop-types";
import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipArrow,
  TooltipReference,
} from "reakit/Tooltip";
import { Button } from "reakit/Button";

import styles from "./Tooltip.module.scss";

const Tooltip = ({ children, text, ...props }) => {
  const tooltip = useTooltipState();

  return (
    <>
      <TooltipReference
        className={styles.tooltip__reference}
        {...tooltip}
        as={Button}
      >
        {children}
      </TooltipReference>
      <ReakitTooltip className={styles.tooltip} {...tooltip} {...props}>
        <TooltipArrow className={styles.tooltip__arrow} {...tooltip} />
        {text}
      </ReakitTooltip>
    </>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
