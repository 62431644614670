import React from "react";

const IconPinStand = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31.3}
    height={40}
    data-name="Group 435"
    viewBox="0 0 173.291 215.914"
    {...props}
  >
    <path
      fill="currentColor"
      d="M151.514 0H21.775A21.777 21.777 0 0 0 0 21.777v125.432a21.777 21.777 0 0 0 21.775 21.777h33.836l29.026 45.806a2.412 2.412 0 0 0 4.057.029l29.952-45.835h32.866a21.777 21.777 0 0 0 21.777-21.777V21.777A21.777 21.777 0 0 0 151.514 0Z"
      data-name="Path 189"
    />
    <g data-name="Group 420">
      <path
        fill="#fff"
        d="M25.129 121.287h2.174v19.732h-2.174z"
        data-name="Rectangle 133"
      />
    </g>
    <g data-name="Group 421">
      <path
        fill="#fff"
        d="M89.578 13.265a3.673 3.673 0 0 0-5.486 0C68.128 32.881 17.383 58.981 17.383 58.981v82.038h5.424v-22.053h4.5V68.909h119.059v50.057h4.117v22.053h5.8V58.981s-50.742-26.1-66.705-45.716Zm44.756 45.546c-1.254-.642-30.689-15.863-40.369-27.756l3.606-2.933c9.069 11.143 38.589 26.41 38.883 26.564Z"
        data-name="Path 190"
      />
    </g>
    <path
      fill="#fff"
      d="M137.516 121.286a12.777 12.777 0 0 0-12.558-10.593 12.758 12.758 0 0 0-25.081-.07 12.76 12.76 0 0 0-25.071 0 12.758 12.758 0 0 0-25.081.067 12.779 12.779 0 0 0-12.559 10.593H25.129v32.5h123.033v-32.5Zm-12.568-5.951a8.133 8.133 0 0 1 7.821 5.951h-15.64a8.133 8.133 0 0 1 7.819-5.95Zm-12.535-10.446a8.133 8.133 0 0 1 7.985 6.656 12.8 12.8 0 0 0-7.985 9.527 12.8 12.8 0 0 0-7.984-9.525 8.134 8.134 0 0 1 7.984-6.657Zm-12.535 10.446a8.132 8.132 0 0 1 7.82 5.951H92.057a8.132 8.132 0 0 1 7.82-5.95Zm-12.535-10.446a8.134 8.134 0 0 1 7.986 6.658 12.8 12.8 0 0 0-7.986 9.525 12.8 12.8 0 0 0-7.985-9.525 8.134 8.134 0 0 1 7.984-6.657Zm-25.071 0a8.133 8.133 0 0 1 7.985 6.656 12.8 12.8 0 0 0-7.985 9.527 12.8 12.8 0 0 0-7.985-9.527 8.133 8.133 0 0 1 7.984-6.655Zm-20.356 16.4a8.114 8.114 0 0 1 15.641 0H41.915Zm25.072 0a8.114 8.114 0 0 1 15.641 0H66.987Z"
      data-name="Path 191"
    />
  </svg>
);

export default IconPinStand;
