import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGlobal } from "frontend/hooks";

import styles from "./FiltersCampaign.module.scss";
import { Radio } from "../../form";

const FiltersCampaign = ({ onChange, isCampaign }) => {
  const { t } = useTranslation();
  const { activeCampaign } = useGlobal();

  if (!activeCampaign?.id) return null;

  const handleChange = (e) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <fieldset className={styles.filtersCampaign}>
      <legend className={styles.filtersCampaign__label}>
        {t("filters.campaign_select_label")}
      </legend>
      <div role="radiogroup" className={styles.filtersCampaign__options}>
        <Radio
          name="campaign"
          value="0"
          defaultChecked={!isCampaign}
          onChange={handleChange}
        >
          {t("filters.default_campaign_select_label")}
        </Radio>
        <Radio
          name="campaign"
          value={activeCampaign.id}
          onChange={handleChange}
          defaultChecked={isCampaign}
        >
          {t("campaign_headline", { ns: "active_campaign" })}
        </Radio>
      </div>
      <p className={styles.filtersCampaign__description}>
        {t("campaign_paragraph", { ns: "active_campaign" })}
      </p>
    </fieldset>
  );
};

FiltersCampaign.propTypes = {
  onChange: PropTypes.func,
  isCampaign: PropTypes.bool,
};

export default FiltersCampaign;
