import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ButtonOrLink from "./ButtonOrLink";

import styles from "./Button.module.scss";

const Button = ({
  children,
  secondary,
  theme,
  block,
  target,
  ...otherProps
}) => (
  <ButtonOrLink
    className={classNames(styles.button, {
      [styles["button--secondary"]]: secondary,
      [styles["button--theme"]]: theme,
      [styles["button--block"]]: block,
    })}
    {...(target === "_blank" && { target, rel: "noopener noreferrer" })}
    {...otherProps}
  >
    {children}
  </ButtonOrLink>
);

Button.propTypes = {
  children: PropTypes.node,
  secondary: PropTypes.bool,
  target: PropTypes.string,
  theme: PropTypes.bool,
  block: PropTypes.bool,
};

export default Button;
